"use client"

import Script from "next/script"
import React from "react"
import { UsersSyncProfile } from "@/firebase/clientApp"
import Bugsnag from "@bugsnag/js"
import { useEffectOnce } from "usehooks-ts"
import {
  OAuthProvider,
  signInWithCredential,
  updateProfile,
} from "@firebase/auth"
import { useFirebase } from "@/firebase/FirebaseProvider"
import { doc, onSnapshot, updateDoc } from "firebase/firestore"

export default function AppleIDClientScript() {
  const { auth, functions, firestore } = useFirebase()

  useEffectOnce(() => {
    const signInOnSuccessHandler = (event: any) => {
      const appleOAuthProvider = new OAuthProvider("apple.com")

      appleOAuthProvider.addScope("email")
      appleOAuthProvider.addScope("name")

      const credential = appleOAuthProvider.credential({
        idToken: event.detail.authorization.id_token,
      })

      signInWithCredential(auth, credential).then((userCredential) => {
        if (
          userCredential.user &&
          (event.detail.user?.name?.firstName ||
            event.detail.user?.name?.lastName)
        ) {
          const firstName: string | undefined =
            event.detail.user.name?.firstName
          const lastName: string | undefined = event.detail.user.name?.lastName
          const fullName = `${firstName} ${lastName}`.trim()

          const user = userCredential.user
          updateProfile(user, { displayName: fullName })
            .catch((error) => {
              Bugsnag.notify(
                new Error("Error when updating user with name from apple", {
                  cause: error,
                }),
              )
            })
            .then(() => UsersSyncProfile(functions))
            .catch((error) => {
              Bugsnag.notify(
                new Error("Error when calling UsersSyncProfile", {
                  cause: error,
                }),
              )
            })
            .then(() => {
              const unsubscribe = onSnapshot(
                doc(firestore, `users/${user.uid}`),
                {
                  next: (q) => {
                    if (
                      q.exists() &&
                      q.data()?.hasOwnProperty("discoverable")
                    ) {
                      updateDoc(q.ref, { name: fullName })
                        .catch((error) => {
                          Bugsnag.notify(
                            new Error(
                              'Error after trying set "name" on user firestore doc',
                              { cause: error },
                            ),
                          )
                        })
                        .finally(() => {
                          unsubscribe()
                        })
                    }
                  },
                  error: (error) => {
                    Bugsnag.notify(
                      new Error(
                        'Error during onSnapshot when trying to set "name" on user firestore doc',
                        { cause: error },
                      ),
                    )
                  },
                },
              )
            })
            .catch((error) => {
              Bugsnag.notify(
                new Error(
                  'Error after trying set "name" on user firestore doc',
                  { cause: error },
                ),
              )
            })
        }
      })
    }

    const signInOnFailureHandler = (event: any) => {
      if (event.detail.error !== "popup_closed_by_user") {
        Bugsnag.notify(
          new Error("signInOnFailureHandler error", {
            cause: event.detail.error,
          }),
        )
      }
    }

    document.addEventListener("AppleIDSignInOnSuccess", signInOnSuccessHandler)
    document.addEventListener("AppleIDSignInOnFailure", signInOnFailureHandler)

    return () => {
      document.removeEventListener(
        "AppleIDSignInOnSuccess",
        signInOnSuccessHandler,
      )
      document.removeEventListener(
        "AppleIDSignInOnFailure",
        signInOnFailureHandler,
      )
    }
  })

  return (
    <Script
      type="text/javascript"
      id="AppleIDClientScript"
      src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    />
  )
}
