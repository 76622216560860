"use client"

import { PropsWithChildren, useEffect, useState } from "react"
import { default as Bugsnag } from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { useClickAnyWhere, useDebounceCallback, useIsClient } from "usehooks-ts"
import _ from "lodash"
import { useFirebase } from "@/firebase/FirebaseProvider"
import { doc, setDoc } from "firebase/firestore"
import { onAuthStateChanged } from "@firebase/auth"

type SessionInfo = {
  deviceID: string
  appStartedAt: string
  deviceModel: "Web"
  appBuild: string
  web: string
  timeZone: string
  activeAt: string
  userID: string
}

const sessionInfoKeys: (keyof SessionInfo)[] = [
  "deviceID",
  "appStartedAt",
  "deviceModel",
  "appBuild",
  "web",
  "timeZone",
  "activeAt",
  "userID",
]

function isSessionInfo(
  sessionInfo: Partial<SessionInfo>,
): sessionInfo is SessionInfo {
  return sessionInfoKeys.every((k) => _.has(sessionInfo, k))
}

export default function Monitoring(props: PropsWithChildren) {
  const { auth, firestore } = useFirebase()

  const apiKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  const releaseStage = process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE
  const appVersion = process.env.NEXT_PUBLIC_BUGSNAG_APP_VERSION
  const isClient = useIsClient()

  const [sessionInfo, setSessionInfo] = useState<Partial<SessionInfo>>(() => ({
    appStartedAt: new Date().toISOString(),
    activeAt: new Date().toISOString(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appVersion: process.env.NEXT_PUBLIC_BUGSNAG_APP_VERSION ?? "unset",
    web: typeof window !== "undefined" ? window.navigator?.userAgent : "unset",
    deviceModel: "Web",
    appBuild: process.env.NEXT_PUBLIC_NEWIE_BUILD_ID ?? "unset",
  }))

  const setActiveAtDebounced = useDebounceCallback(() => {
    console.debug("[Newie] Update activeAt in SessionInfo")
    setSessionInfo((prevState) => {
      return {
        ...prevState,
        activeAt: new Date().toISOString(),
      }
    })
  }, 10_000)

  useClickAnyWhere(setActiveAtDebounced)

  useEffect(() => {
    if (isClient && isSessionInfo(sessionInfo) && auth.currentUser?.uid) {
      console.debug("[Newie] Persist SessionInfo to Firestore")

      const path = `users/${sessionInfo.userID}/devices/${sessionInfo.deviceID}`
      const docRef = doc(firestore, path)
      setDoc(docRef, _.pick(sessionInfo, sessionInfoKeys), {
        merge: true,
      }).catch((err) =>
        Bugsnag.notify(new Error("PersistSessionInfo error", { cause: err })),
      )
    }
  }, [auth.currentUser?.uid, firestore, isClient, sessionInfo])

  useEffect(() => {
    if (isClient && !Bugsnag.isStarted() && apiKey) {
      console.debug("[Newie] Start Bugsnag")
      const client = Bugsnag.start({
        apiKey,
        releaseStage,
        appVersion,
        plugins: [new BugsnagPluginReact()],
      }) as any

      const deviceID = client._session?.device?.id

      if (deviceID) {
        console.debug("[Newie] Add deviceID to SessionInfo")
        setSessionInfo((prevState) => ({ ...prevState, deviceID }))
      } else {
        console.warn("[Newie] SessionInfo did not contain deviceID")
        Bugsnag.notify("SessionInfo did not contain deviceID")
      }
    }
  }, [isClient, apiKey, appVersion, releaseStage])

  useEffect(() => {
    if (isClient) {
      console.debug("[Newie] Create onAuthStateChange observer")
      return onAuthStateChanged(auth, (maybeUser) => {
        console.debug("[Newie] Observed onAuthStateChanged")
        setSessionInfo((prevState) => {
          if (maybeUser?.uid) {
            console.debug("[Newie] Add user to SessionInfo")
            return { ...prevState, userID: maybeUser.uid }
          } else {
            console.debug("[Newie] Remove user from SessionInfo")
            const { userID: _, ...prevStateWithoutUserID } = prevState
            return prevStateWithoutUserID
          }
        })
      })
    }
  }, [auth, isClient])

  return <>{props.children}</>
}
