'use client'

import {createContext, PropsWithChildren, useEffect} from 'react'


function openModal() {
    const body = document.body
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    body.style.top = `-${scrollY}`
    body.style.position = 'fixed'
}

function closeModal() {
    const body = document.body
    const scrollY = body.style.top
    body.style.position = ''
    body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

export type ModalContextProps = {
    openModal: Function,
    closeModal: Function
}

export const ModalContext = createContext<ModalContextProps>({openModal, closeModal})

export function ModalContextProvider({children}: PropsWithChildren) {

    useEffect(() => {
        const scrollEventListener = () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
        }

        window.addEventListener('scroll', scrollEventListener)

        return () => {
            window.removeEventListener('scroll', scrollEventListener)
        }
    }, [])

    return (
        <ModalContext.Provider value={{openModal, closeModal}}>
            {children}
        </ModalContext.Provider>
    )
}
