'use client'

import {createContext, PropsWithChildren, useEffect, useState} from 'react'
import {usePathname} from 'next/navigation'

export type RouteHistory = string[]

export const RouteHistoryContext = createContext<RouteHistory>([])

export default function RouteHistoryContextProvider({children}: PropsWithChildren) {

    const [routeHistory, setRouteHistory] = useState<RouteHistory>([])
    const pathname = usePathname()

    useEffect(() => {
        setRouteHistory((routeHistory: RouteHistory) => {
            return [...routeHistory, pathname]
        })
    }, [pathname])

    return (
        <RouteHistoryContext.Provider value={routeHistory}>
            {children}
        </RouteHistoryContext.Provider>
    )
}
