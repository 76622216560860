"use client"

import Script from "next/script"
import React, { createContext, PropsWithChildren, useState } from "react"
import { GoogleAuthProvider, signInWithCredential } from "@firebase/auth"
import Bugsnag from "@bugsnag/js"
import { LoadingSpinnerAndMessageMainSection } from "@/components/LoadingSpinner"
import { useEffectOnce, useTimeout } from "usehooks-ts"
import Header from "@/components/site/Header"
import Footer from "@/components/site/Footer"
import { useFirebase } from "@/firebase/FirebaseProvider"

export type GSIClientScriptContextProps = {
  isLoading: boolean
  renderButton: (parent: HTMLElement, options: any) => void
  isLoaded: boolean
}

const renderButtonFallbackFn = (_parent: HTMLElement, _options: any) => {
  Bugsnag.notify(
    new Error(
      `An attempt was made to render the the Google SSO button which should not have been possible`,
    ),
  )
}

export const GSIClientScriptContext =
  createContext<GSIClientScriptContextProps>({
    isLoading: true,
    isLoaded: false,
    renderButton: renderButtonFallbackFn,
  })

declare global {
  interface Window {
    onGoogleLibraryLoad?: () => void
    google?: {
      accounts?: {
        id?: any
      }
    }
  }
}

export default function GSIClientScript({ children }: PropsWithChildren) {
  const { auth } = useFirebase()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const handleCredentialResponse = (response: any) => {
    const credential = GoogleAuthProvider.credential(response.credential)
    signInWithCredential(auth, credential).catch((error: any) => {
      Bugsnag.notify(
        new Error("Error when signInWithCredential", { cause: error }),
      )
    })
  }

  // Just finish loading if google-gsi taking too long
  const finishLoading = () => setIsLoading(false)
  useTimeout(finishLoading, 5000)

  useEffectOnce(() => {
    window.onGoogleLibraryLoad = () => {
      if (window.google?.accounts?.id) {
        window.google.accounts.id.initialize({
          client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        })
        setIsLoaded(true)
      }
      setIsLoading(false)
    }
  })

  const renderButton = !isLoaded
    ? renderButtonFallbackFn
    : (parent: HTMLElement, options: any) => {
        if (window.google?.accounts?.id) {
          window.google.accounts.id.renderButton(parent, options)
        } else {
          Bugsnag.notify(
            new Error(
              `Google GSI library was supposed to be loaded, but it wasn't.`,
            ),
          )
        }
      }

  return (
    <GSIClientScriptContext.Provider
      value={{ isLoading, isLoaded, renderButton }}
    >
      <Script
        type="text/javascript"
        id="GSIClientScript"
        src="https://accounts.google.com/gsi/client"
        onError={() => {
          setIsLoading(false)
        }}
      />
      {isLoading ? (
        <div
          className={
            "mx-auto flex min-h-[100dvh] max-w-[600px] flex-col items-center justify-between px-[24px]"
          }
        >
          <Header />
          <LoadingSpinnerAndMessageMainSection />
          <Footer />
        </div>
      ) : (
        children
      )}
    </GSIClientScriptContext.Provider>
  )
}
